<template>
  <div class="new-advertising">
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      class="customBreadcrumb"
    >
      <el-breadcrumb-item>直播营销</el-breadcrumb-item>
      <el-breadcrumb-item>广告投放</el-breadcrumb-item>
      <el-breadcrumb-item>创建计划</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form
      :model="infoList"
      :rules="rules"
      ref="infoList"
      label-width="100px"
      class="demo-ruleForm"
    >
      <!-- 标题 -->
      <div class="ad-title">投放设置</div>
      <!-- 投放设置 -->
      <div class="delivery-settings-main layout-common">
        <!-- 投放方式 -->
        <div class="common-title">投放方式</div>
        <div class="cost-delivery" style="margin-top: 19px">
          <span style="margin-bottom: 10px">控成本投放</span>
          <span class="cost-delivery-tag"
            >优先控制成本达标,最大限度使用广告预算</span
          >
        </div>
        <el-checkbox
          style="margin-top: 11px"
          :true-label="1"
          :false-label="1"
          v-model="infoList.control_cost_online"
          >严格控制成本上限</el-checkbox
        >
        <!-- 投放速度 -->
        <div class="delivery-speed" style="margin-top: 31px">
          <div class="common-title" style="margin-bottom: 10px">投放速度</div>
          <div class="delivery-option">
            <!-- 尽快投放 -->
            <div
              :class="[
                'delivery-common-btn delivery-common',
                infoList.delivery_speed === 1 ? '' : 'delivery-unchecked',
              ]"
              @click="hadleCick_deliverySpeed(1)"
              style="width: 114px; height: 30px"
            >
              尽快投放
              <el-tooltip
                class="item"
                effect="dark"
                content="如遇合适流量，预算集中投放"
                placement="top"
                popper-class="draw_share_atooltip"
              >
                <span class="iconfont" style="color: #ababbb">&#xe604;</span>
              </el-tooltip>
            </div>
            <!-- 均匀投放 -->
            <div
              :class="[
                'delivery-common-btn  delivery-common',
                infoList.delivery_speed === 2 ? '' : 'delivery-unchecked',
              ]"
              @click="hadleCick_deliverySpeed(2)"
              style="width: 114px; height: 30px"
            >
              均匀投放
              <el-tooltip
                class="item"
                effect="dark"
                content="全天预算平滑投放"
                placement="top"
                popper-class="draw_share_atooltip"
              >
                <span class="iconfont" style="color: #ababbb">&#xe604;</span>
              </el-tooltip>
            </div>
          </div>
        </div>
        <!-- 转换目标 -->
        <div class="transformation-target" style="margin-top: 25px">
          <div class="common-title">转换目标</div>
          <div class="liveroom-box" style="margin-top: 20px">
            <div class="iconfont live-icon">&#xe65f;</div>
            <div class="liveroom-more">
              <span style="margin-bottom: 10px">进入直播间</span>
              <span
                class="cost-delivery-tag"
                style="color: #ababbb; font-size: 12px"
                >更多人进入直播间</span
              >
            </div>
          </div>
        </div>
        <!-- 投放时间 -->
        <div class="launch-time" style="margin-top: 30px">
          <div class="common-title">投放时间</div>
          <div
            class="time-tag"
            style="margin: 21px 0 10px 0; font-size: 12px; color: #ffffff"
          >
            所选时间范围内，仅直播时进行投放
          </div>
          <div class="launch-time-box">
            <div
              class="delivery-common-btn delivery-common"
              style="width: 96px; height: 30px"
            >
              固定时段
            </div>
          </div>
          <div class="fixed-period-table" style="margin-top: 10px;width:50%">
            <table class="period-table" >
              <thead class="period-table-header">
                <tr>
                  <th colspan="130" style="height: 30px">00:00 - 60:00</th>
                </tr>
                <tr>
                  <td
                    colspan="2"
                    v-for="(item, index) in tableHeader"
                    :key="index"
                    style="height: 35px; width: 220px"
                  >
                    {{ item }}
                  </td>
                </tr>
              </thead>
              <!-- 星期 -->
              <tr
                style="height: 30px; line-height: 30px"
                v-for="(item, index) in weekDate"
                :key="index"
              >
                <td
                  colspan="2"
                  style="text-align: center; cursor: pointer"
                  v-for="(td_item, td_index) in item.data"
                  :key="td_index"
                  @click="getItemValue(index, td_index)"
                  :class="
                    td_item.check
                      ? ' chosen_td'
                      : td_item.disable
                      ? 'disable-td'
                      : ''
                  "
                ></td>
              </tr>
              <!-- 清空 -->
              <td
                style="
                  height: 30px;
                  line-height: 30px;
                  border-right-style: none;
                "
                colspan="86"
              >
                <el-link
                  :underline="false"
                  class="btn-common"
                  style="float: right; border: none"
                  @click="cleartable"
                  >清空</el-link
                >
              </td>
            </table>
          </div>
        </div>

        <!-- 预算 -->
        <div class="dail-budget" style="margin-top: 15px">
          <div class="common-title" style="margin-bottom: 16px">预算</div>
          <el-form-item prop="day_budget">
            <el-input-number
              :controls="false"
              :min="100"
              :max="10000"
              :precision="2"
              v-model="infoList.day_budget"
              @blur="getDireData"
              placeholder="请输入金额"
              style="width: 280px"
            ></el-input-number>
            <span style="margin-left: 10px">元</span>
          </el-form-item>
        </div>
        <!-- 出价 -->
        <div class="offer-box" style="margin-top: 29px">
          <div class="common-title" style="margin-bottom: 16px">出价</div>
          <el-form-item prop="price">
            <el-input-number
              :precision="2"
              :controls="false"
              :min="0.01"
              :max="100"
              v-model="infoList.price"
              @blur="getDireData"
              placeholder="请输入价格"
              style="width: 280px"
            ></el-input-number>
            <span style="margin-left: 10px">元/展现次数</span>
          </el-form-item>
        </div>
        <!-- 尾 -->
      </div>
      <!-- 标题 -->
      <div class="population-title" style="margin-top: 18px">
        <span class="ad-title">定向人群</span>
      </div>
      <!-- 定向人群 -->
      <div class="population-main layout-common">
        <div class="population-main-left">
          <!-- 地域 -->
          <div class="population-main-region">
            <div class="common-title" style="margin-bottom: 20px">地域</div>
            <div class="region-option">
              <el-checkbox-group
                v-model="infoList.city"
                @change="changeCityStatus"
              >
                <el-checkbox-button
                  false-label=""
                  style="margin-right: 20px"
                  label="不限"
                  >不限</el-checkbox-button
                >
              </el-checkbox-group>
              <el-cascader
                ref="area"
                @change="showRightCityName()"
                v-model="infoList.city"
                :options="areaArr"
                :props="keyObj"
                :disabled="infoList.city.includes('不限')"
                clearable
                collapse-tags
                :show-all-levels="false"
                style="width: 177px; border: none"
                placeholder="按省市"
                size="small"
                multiple="true"
              >
              </el-cascader>
            </div>
          </div>
          <!-- 性别 -->
          <div class="population-main-sex" style="margin-top: 30px">
            <div class="common-title">性别</div>
            <div class="sex-option" style="margin-top: 20px">
              <el-checkbox-group
                v-model="infoList.gender"
                size="medium"
                @change="getDirectionData"
              >
                <el-checkbox-button
                  true-label="0"
                  false-label=""
                  style="width: 82px; margin-right: 20px"
                  border="true"
                  >不限</el-checkbox-button
                >
                <el-checkbox-button
                  true-label="1"
                  false-label=""
                  style="width: 82px; margin-right: 20px"
                  border="true"
                  :disabled="infoList.gender.includes(0)"
                  >男</el-checkbox-button
                >
                <el-checkbox-button
                  true-label="2"
                  false-label=""
                  style="width: 82px; margin-right: 20px"
                  :disabled="infoList.gender.includes(0)"
                  >女</el-checkbox-button
                >
              </el-checkbox-group>
            </div>
          </div>
          <!-- 年龄 -->
          <div class="population-main-age" style="margin-top: 30px">
            <div class="common-title">年龄</div>
            <div class="age-option" style="margin-top: 20px">
              <div class="age-check">
                <el-checkbox-group
                  v-model="infoList.age"
                  @change="getDirectionData"
                >
                  <el-checkbox-button
                    @change="changeAgeItem()"
                    style="margin-right: 20px"
                    label="不限"
                  >
                    不限
                  </el-checkbox-button>
                  <el-checkbox-button
                    style="margin-right: 20px"
                    v-for="age in age_cities"
                    :label="age"
                    :key="age"
                    :disabled="infoList.age.includes('不限')"
                    >{{ age }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <!-- 行为兴趣意向 -->
          <div class="population-main-interest" style="margin-top: 30px">
            <div class="common-title">行为兴趣意向</div>
            <div class="interset-option" style="margin-top: 20px">
              <el-checkbox-group
                v-model="actionStatus"
                size="medium"
                @change="changeStatus"
              >
                <el-checkbox-button
                  label="不限"
                  true-label="不限"
                  false-label="0"
                  style="margin-right: 20px"
                  border="true"
                ></el-checkbox-button>
                <el-checkbox-button
                  label="自定义"
                  true-label="自定义"
                  false-label=""
                  style="margin-right: 20px"
                  border="true"
                ></el-checkbox-button>
              </el-checkbox-group>
              <el-cascader
                @change="showRightInterset"
                class="interset-show"
                v-if="actionStatus == '自定义'"
                v-model="infoList.action"
                :options="intersetOptions"
                multiple="true"
                :props="intersetObj"
                collapse-tags
                clearable
                :show-all-levels="false"
                ref="interset"
              ></el-cascader>
            </div>
          </div>
          <!-- 网络 -->
          <div class="population-main-network" style="margin-top: 30px">
            <div class="common-title">网络</div>
            <div class="network-option" style="margin-top: 20px">
              <div class="network-check">
                <el-checkbox-group
                  v-model="infoList.network"
                  @change="getDirectionData"
                >
                  <el-checkbox-button
                    style="margin-right: 20px"
                    label="不限"
                    @change="changeNetworkItem()"
                  >
                    不限
                  </el-checkbox-button>
                  <el-checkbox-button
                    style="margin-right: 20px"
                    v-for="network in network_cities"
                    :label="network"
                    :key="network"
                    :disabled="infoList.network == '不限'"
                    >{{ network }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <!-- 平台 -->
          <div class="population-main-platform" style="margin-top: 30px">
            <div class="common-title">平台</div>
            <div class="platform-option" style="margin-top: 20px">
              <el-checkbox-group
                v-model="infoList.platform"
                size="medium"
                @change="changePlarform"
              >
                <el-checkbox-button
                  label="不限"
                  style="margin-right: 20px"
                  border="true"
                  >不限</el-checkbox-button
                >
                <el-checkbox-button
                  style="margin-right: 20px"
                  v-for="platform in platform_cities"
                  :label="platform"
                  :key="platform"
                  :disabled="infoList.platform.includes('不限')"
                  >{{ platform }}</el-checkbox-button
                >
              </el-checkbox-group>
            </div>
          </div>
          <!-- 更多人群 -->
          <div class="population-main-more" style="margin-top: 30px">
            <div class="common-title">更多人群</div>
            <div class="more-option">
              <el-checkbox-group v-model="infoList.more_people">
                <el-checkbox-button
                  label="不限"
                  style="margin-right: 20px"
                  @change="changeMoreStatus"
                  >不限</el-checkbox-button
                >
                <el-checkbox-button
                  label="更多人群"
                  :class="[
                    'delivery-common-btn',
                    'delivery-common',
                    more_status === 'more' ? '' : 'delivery-unchecked',
                  ]"
                  @change="hadleCick_MoreStatus"
                  :disabled="infoList.more_people.includes('不限')"
                >
                  更多人群
                </el-checkbox-button>
              </el-checkbox-group>
            </div>
            <!-- 点击更多人群展开 -->
            <div class="more-checkbox" v-if="more_status === 'more'">
              <div class="more-checkbox-title" style="margin-bottom: 15px">
                平台精选
              </div>
              <el-checkbox-group
                v-model="infoList.more_people"
                @change="showRightMoreName"
              >
                <el-checkbox
                  v-for="(item, index) in moreCheckList"
                  :label="`${item.crowd}`"
                  :key="index"
                  :value="item.crowd"
                >
                  <div>
                    <div>
                      {{ item.selectedName
                      }}<span class="more-tag">{{ item.tag }}</span>
                    </div>
                  </div>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <!-- 右边表格 -->
        <div class="population-main-right">
          <div class="common-title" style="font-size: 16px; font-weight: bold">
            预估定向
          </div>
          <div class="estimate-data">
            <!-- 广告展示数 -->
            <div class="advertisement-data">
              <span class="title-common">广告展示数</span>
              <div>
                <div class="coverage-data-num">
                  <span style="font-size: 24px">{{
                    parseInt(advertisement)
                  }}</span>
                  <span
                    style="margin-left: 9px; font-weight: bold; font-size: 16px"
                    >万</span
                  >
                </div>
              </div>
            </div>
            <!-- 用户覆盖数 -->
            <div class="coverage-data">
              <span class="title-common">用户覆盖数</span>
              <div class="coverage-data-num">
                <span style="font-size: 24px">{{ parseInt(coverage) }}</span>
                <span style="margin-left: 9px; font-size: 16px">万</span>
              </div>
            </div>
          </div>
          <!-- 已选定向信息 -->
          <div style="margin-top: 20px">
            <div style="font-weight: bold">已选定向信息</div>
            <div style="margin-top: 25px">
              地域:<span>{{
                infoList.city == "不限"
                  ? "不限"
                  : rightCityName &&
                    rightCityName.map((item) => item).join("、")
              }}</span>
            </div>
            <div style="margin-top: 50px">
              性别:<span>{{
                infoList.gender
                  ? infoList.gender == 0
                    ? "不限"
                    : infoList.gender == "1"
                    ? "男"
                    : infoList.gender == 2
                    ? "女"
                    : ""
                  : ""
              }}</span>
            </div>
            <div style="margin-top: 50px">
              年龄:<span>{{ infoList.age && infoList.age.join("、") }}</span>
            </div>
            <div style="margin-top: 50px">
              行为兴趣意向:<span>{{
                infoList.action.length != 0
                  ? infoList.action == "不限"
                    ? "不限"
                    : rightIntersetName &&
                      rightIntersetName.map((item) => item).join("、")
                  : ""
              }}</span>
            </div>
            <div style="margin-top: 50px">
              网络:<span style="color: #ffffff">{{
                infoList.network && infoList.network == "不限"
                  ? "不限"
                  : infoList.network.join("、")
              }}</span>
            </div>

            <!-- <span style="color: #ffffff">{{
                infoList.platform.length != 0
                  ? infoList.platform == "不限"
                    ? "不限"
                    : infoList.platform == "ios"
                    ? "IOS"
                    : infoList.platform == "android"
                    ? "安卓"
                    : ""
                  : ""
              }}</span> -->

            <div style="margin-top: 50px">
              平台:
              <span>{{
                infoList.platform && infoList.platform.join("、")
              }}</span>
            </div>
            <div class="more-crowd" style="margin-top: 50px">
              更多人群:<span>{{
                infoList.more_people == "不限"
                  ? "不限"
                  : rightMoreName &&
                    rightMoreName.map((item) => item).join("、")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 标题 -->
      <div class="ad-title">请为直播间增加创意</div>
      <!-- 请为直播间增加创意 -->
      <div class="live-room-main layout-common">
        <!-- 创意标签-->
        <div class="room-main-tag">
          <div class="common-title">创意标签</div>
          <!-- 添加标签 -->
          <div class="main-tag-add-box" style="margin-top: 20px">
            <el-form-item>
              <el-input
                v-model="tagValue"
                placeholder="请输入标签，长度最多十个字符"
                style="width: 295px"
                :maxlength="10"
              ></el-input>
            </el-form-item>
            <el-button
              type="primary"
              style="margin-left: 10px; background: #4b45ff"
              @click="addToTagOptions"
              >添加</el-button
            >
          </div>

          <!-- 创意标签多选 -->
          <div class="main-tag-check-box" style="margin-top: 18px">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="tagCheckAll"
              @change="handleCheckAllChange"
              >全选
              <span style="margin-left: 5px"
                >(已选{{ infoList.idea_label.length }}/{{
                  tagOptions.length
                }})</span
              >
            </el-checkbox>
            <div
              class="iconfont main-tag-check-del"
              style="color: #ff3e6c; font-size: 19px"
              @click="delCheckItem"
            >
              &#xe613;
            </div>
            <div class="main-tag-check-item">
              <el-checkbox-group v-model="infoList.idea_label" @change="test">
                <el-checkbox
                  v-for="(tag, index) in tagOptions"
                  :label="tag"
                  :key="index"
                  >{{ tag }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>

          <!-- 计划名称 -->
          <div class="common-title" style="margin: 30px 0 20px 0">计划名称</div>
          <el-form-item prop="plan_name">
            <el-input
              v-model="infoList.plan_name"
              style="width: 380px"
              placeholder="请输入计划名称(2/50)"
            ></el-input>
          </el-form-item>

          <!-- 协议 -->
          <div class="main-tag-agreement" style="margin-top: 30px">
            <el-checkbox v-model="agreement"
              >我已阅读并同意<a href="#" style="color: #ffffff"
                >《直播间引流承诺函》</a
              ></el-checkbox
            >
          </div>
        </div>
      </div>
    </el-form>
    <!-- 提交 -->
    <div class="submit-btn">
      <el-button @click="cancel" class="custorm" plain type="primary"
        >取消</el-button
      >
      <el-button
        style="background: #4b45ff"
        type="primary"
        @click="releasePlan('infoList')"
        >发布计划</el-button
      >
    </div>
  </div>
</template>

<script>
import { addAd, showBasicData, getAdInfoData, editAd } from "@/utils/apis";
export default {
  data() {
    var validateBudget = (rules, value, callback) => {
      if (value < 100) {
        callback(new Error("预算不少于100元"));
      } else if (value > 10000) {
        callback(new Error("预算不超过10000"));
      } else {
        callback();
      }
    };
    var validateOffer = (rules, value, callback) => {
      if (value < 0.01) {
        callback(new Error("出价不少于0.1"));
      } else if (value > 100) {
        callback(new Error("出价不超过100"));
      } else {
        callback();
      }
    };
    return {
      infoList: {
        control_cost_online: 1, //是否严格控制成本上限，0不控制，1控制
        delivery_speed: "", //投放速度，0尽快投放，1均匀投放
        fixed_period: {}, //固定时段
        day_budget: "", //日预算
        price: "", //出价
        city: [], //省市
        gender: "", //性别
        age: [], //年龄
        action: [], //兴趣意向
        network: [], //网络
        platform: [], //平台
        more_people: [], //平台精选复选框数据
        idea_label: [], //创意标签数据
        plan_name: "", //计划名称
      },
      tableHeader: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
      ],
      weekDate: [
        {
          data: [],
        },
      ],
      deliverySpeed: "fast", //投放速度状态更改css样式 默认值选中
      age_cities: [], //年龄选择
      platform_cities: ["ios", "android"],
      actionStatus: "", //行为兴趣意向状态
      network_cities: ["wifi", "2g", "3g", "4g"], //网络选择
      more_status: "unlimited", //更多人群状态，默认不限
      agreement: false, //协议，默认false
      advertisement: 0, //广告展示数
      coverage: 0, //用户展示数
      tagOptions: [], //创意标签
      tagCheckAll: false, //全选，默认false
      isIndeterminate: true, //表示 checkbox 的不确定状态，一般用于实现全选的效果 默认true
      moreCheckList: [
        {
          selectedName: "促销敏感人群",
          tag: "定向",
          crowd: "promotion_sensitive",
        },
        {
          selectedName: "高订单取消/退货人群",
          tag: "排除",
          crowd: "high_order",
        },
        {
          selectedName: "高关注数人群",
          tag: "排除",
          crowd: "high_profile",
        },
        {
          selectedName: "高活跃人群",
          tag: "排除",
          crowd: "high_active",
        },
        {
          selectedName: "抖音号粉丝",
          tag: "排除",
          crowd: "tiktok_fans",
        },
      ], //平台精选复选框内容
      rightMoreName: "", //显示更多人群名称
      areaArr: [
        {
          city_name: "一线城市",
          children: "",
        },
        {
          city_name: "新一线城市",
          children: "",
        },
        {
          city_name: "二线城市",
          children: "",
        },
        {
          city_name: "三线城市",
          children: "",
        },
        {
          city_name: "四线城市",
          children: "",
        },
        {
          city_name: "五线城市",
          children: "",
        },
      ], //城市级联
      cityId: "", //获取选中城市id
      rightCityName: [], //显示右侧城市名称
      keyObj: {
        value: "city_id",
        label: "city_name",
        children: "children",
        multiple: true,
      }, //绑定，显示城市信息
      // 行为兴趣意向状态，如果是自定义显示下拉级联选择器
      intersetStatus: "不限", //判断下拉级联显示和隐藏
      intersetObj: {
        value: "category_id",
        label: "category_name",
        children: "children",
        multiple: true,
      }, //绑定，显示兴趣信息
      // 行为兴趣意向自定义选项
      intersetOptions: [], //行为兴趣级联
      rightIntersetName: [],
      tagValue: "", //创意标签文本框接收值
      rules: {
        day_budget: [
          {
            validator: validateBudget,
            trigger: "change",
          },
        ],
        price: [
          {
            validator: validateOffer,
            trigger: "change",
          },
        ],
        plan_name: [
          { required: true, message: "请正确输入", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "计划名称最小2个字，最多50个字",
            trigger: "change",
          },
        ],
      }, // 验证规则
      tableDate: [], //获取可选广告时间
      code: "", //唯一标识码
      editTable: "",
    };
  },
  mounted() {
    this.getBasicData();
  },
  methods: {
    test() {
      console.log(this.infoList.idea_label);
    },
    changePlarform() {
      if (this.infoList.platform.includes("不限")) {
        this.infoList.platform = ["不限"];
        this.getDirectionData();
      } else {
        this.getDirectionData();
      }
    },
    // 获取基本信息
    async getBasicData() {
      let params = {
        authorization: localStorage.getItem("studentToken") || "",
      };
      let res = await showBasicData(params);
      if (res.code === 200) {
        this.age_cities = res.data.age;
        this.intersetOptions = res.data.industry_data;
        this.areaArr.forEach((item) => {
          switch (item.city_name) {
            case "一线城市":
              item.children = res.data.region.first_city;
              break;
            case "新一线城市":
              item.children = res.data.region.new_first_city;
              break;
            case "二线城市":
              item.children = res.data.region.second_city;
              break;
            case "三线城市":
              item.children = res.data.region.third_city;
              break;
            case "四线城市":
              item.children = res.data.region.fourth_city;
              break;
            case "五线城市":
              item.children = res.data.region.fifth_city;
              break;
          }
        });
        this.tableDate = res.data.activity_time;
        this.tableInit();
        if (this.$route.query.id) {
          this.getEditInfo();
        }
      }
    },
    // 设置兴趣意向，如果选择是自定义就展开多选菜单
    changeStatus() {
      if (this.actionStatus == "不限") {
        this.infoList.action = this.actionStatus;
        this.getDirectionData();
      } else if (this.actionStatus == "自定义") {
        this.intersetStatus = "自定义";
      } else if (this.actionStatus == 0) {
        this.infoList.action = "";
        this.getDirectionData();
      }
    },
    // 勾选兴趣右边表格显示名称
    showRightInterset() {
      this.rightIntersetName = this.$refs.interset
        .getCheckedNodes()
        .reduce(
          (sum, item) =>
            sum.concat(item.children.length <= 0 ? [item.label] : []),
          []
        );
      this.getDirectionData();
    },
    // 设置更多人群，如果选择不限，禁用更多人群选项
    changeMoreStatus() {
      this.more_status = "unlimited";
      if (this.infoList.more_people.includes("不限")) {
        this.infoList.more_people = ["不限"];
        this.rightMoreName = "";
        this.getDirectionData();
      } else {
        this.getDirectionData();
      }
    },
    // 设置城市，如果选择不限，禁用下拉级联
    changeCityStatus() {
      if (this.infoList.city.includes("不限")) {
        this.infoList.city = ["不限"];
        this.getDirectionData();
      } else {
        this.rightCityName = [];
        this.getDirectionData();
      }
    },
    // 勾选城市，右边表格显示对应城市名称
    showRightCityName() {
      this.rightCityName = this.$refs.area
        .getCheckedNodes()
        .reduce(
          (sum, item) =>
            sum.concat(item.children.length <= 0 ? [item.label] : []),
          []
        );
      this.getDirectionData();
    },
    // 表格初始化
    tableInit() {
      // 表格操作
      let arr = new Array(61).fill({});
      arr = arr.map((item) => {
        let obj = { check: false, disable: false };
        return obj;
      });
      this.weekDate.forEach((item) => {
        item.data = JSON.parse(JSON.stringify(arr));
        item.data.forEach((subItem, subIndex) => {
          if (!this.tableDate.includes(subIndex)) {
            subItem.disable = true;
          }
        });
      });
    },
    // 获取星期下标和单元格下标更改单元格背景色
    getItemValue(index, subIndex) {
      // 给格子添加背景色
      let item = this.weekDate[index].data[subIndex];
      if (this.tableDate.some((item) => item == subIndex)) {
        item.check = !item.check;
        this.infoList.fixed_period = {};
        this.weekDate.forEach((item, index) => {
          let arr = [];
          item.data.forEach((subItem, subIndex) => {
            if (subItem.check) {
              let str = `${subIndex}`;
              arr.push(str);
            }
          });
          if (arr.length > 0) {
            this.infoList.fixed_period[index + 1] = arr;
            if (this.$route.query.id) {
              this.getDirectionData();
            }
          }
        });
      }
      // } else {
      //   this.$message({
      //     type: "warning",
      //     message: "该时间段已有广告",
      //   });
      // }
    },
    // 清空表格
    cleartable() {
      if (this.infoList.fixed_period == "") {
        this.$message({
          type: "error",
          message: "没有可选项需要清空!",
        });
      } else {
        this.$confirm("确定清空所选项, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (this.$route.query.id) {
              this.weekDate.forEach((item) => {
                item.data.forEach((subItem) => {
                  subItem.check = false;
                });
              });
              this.editTable = [];
            } else {
              this.weekDate.forEach((item) => {
                item.data.forEach((subItem) => {
                  subItem.check = false;
                });
              });
              this.infoList.fixed_period = "";
              this.$message({
                type: "success",
                message: "清空成功!",
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    // 预算出价
    getDireData() {
      this.$route.query.id ? this.getDirectionData() : this.getDirectionData();
    },
    //修改投放速度状态，更改css
    hadleCick_deliverySpeed(num) {
      if (num == 1) {
        this.infoList.delivery_speed = 1;
        this.$route.query.id ? this.getDirectionData() : "";
      } else {
        this.infoList.delivery_speed = 2;
        this.$route.query.id ? this.getDirectionData() : "";
      }
    },
    // 点击年龄和网络复选框 判断是否 “不限”，如果是清空其他选项
    changeAgeItem() {
      if (this.infoList.age.includes("不限")) {
        this.infoList.age = ["不限"];
      }
    },
    changeNetworkItem() {
      if (this.infoList.network.includes("不限")) {
        this.infoList.network = ["不限"];
      }
    },
    // 点击修改更多人群状态 css 状态切换对数组的操作
    hadleCick_MoreStatus() {
      if (this.more_status === "unlimited") {
        this.more_status = "more";
        this.infoList.more_people.shift();
      }
    },
    // 点击更多人群右边表格显示名称
    showRightMoreName() {
      this.rightMoreName = this.infoList.more_people.reduce((sum, cur) => {
        let obj = this.moreCheckList.find((ele) => ele.crowd == cur);
        return sum.concat(obj.selectedName);
      }, []);
      this.getDirectionData();
    },

    // 将创意标签文本框输入的值存入tagOptions数组中
    addToTagOptions() {
      if (this.tagOptions.length > 9) {
        this.$message({
          type: "error",
          message: "最多添加十个标签",
        });
        return false;
      } else if (this.tagValue == "") {
        this.$message({
          type: "error",
          message: "标签名称不能为空",
        });
        return false;
      } else {
        this.tagOptions.push(this.tagValue);
        this.tagValue = "";
      }
    },
    // 创意标签全选
    handleCheckAllChange(val) {
      console.log(this.tagOptions);
      this.infoList.idea_label = val ? this.tagOptions : [];
      console.log(this.infoList.idea_label);
      this.isIndeterminate = false;
    },
    // 清空标签
    delCheckItem() {
      console.log(this.infoList.idea_label);
      this.$confirm("此操作将删除数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "msgBoxClass",
      })
        .then(() => {
          //把不包含在你要删除的数据里的过滤掉
          let tag = this.tagOptions.filter(
            (item) => !this.infoList.idea_label.includes(item)
          );
          // 判断idea_label的长度是否大于0，选中后长度才会大于0。未选中直接点击删除弹窗警告
          if (this.infoList.idea_label.length === 0) {
            this.$message({
              type: "error",
              message: "请选中要删除的数据",
            });
          } else {
            this.tagOptions = tag ? tag : [];
            this.infoList.idea_label = [];
            this.isIndeterminate = false;
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 发布计划
    releasePlan(forName) {
      if (this.agreement == true) {
        this.$refs[forName].validate((valid) => {
          if (valid) {
            if (this.code) {
              let params = {
                unique_code: this.code,
                advertising_student_config_name: this.infoList.plan_name,
                tag: JSON.stringify(this.infoList.idea_label),
              };
              if (this.infoList.idea_label.length!=0) {
                params.tag = JSON.stringify(this.infoList.idea_label);
                if (this.$route.query.id) {
                  params.advertising_student_config_id = this.$route.query.id;
                }
                addAd(params)
                  .then((res) => {
                    if (res.code === 200) {
                      this.$message({
                        type: "success",
                        message: res.msg,
                      });
                      this.$router.push({
                        path: "/liveExpand/adLaunch",
                      });
                    }
                  })
                  .catch((err) => {
                    this.$message({
                      type: "error",
                      message: err,
                    });
                  });
              } else {
                this.$message.warning("请勾选创意标签");
                return false;
              }
            } else {
              this.$message.warning("请补充好投放信息");
            }
          }
        });
      } else {
        this.$message({ type: "warning", message: "请勾选协议" });
      }
    },
    // 更新计划
    getEditInfo() {
      let params = {
        advertising_student_config_id: this.$route.query.id,
      };
      editAd(params).then((res) => {
        if (res.code === 200) {
          this.infoList.day_budget = res.data.budget;
          this.infoList.price = res.data.price;
          this.infoList.delivery_speed = res.data.put_in_type;
          this.infoList.plan_name = res.data.advertising_student_config_name;
          this.advertisement = res.data.adv_show_num;
          this.coverage = res.data.user_cover_num;
          this.tagOptions = JSON.parse(res.data.tag);
          if (res.data.sex != null) {
            this.infoList.gender = res.data.sex ? res.data.sex : "";
          }
          if (res.data.age != null) {
            res.data.age == "[]"
              ? (this.infoList.age = ["不限"])
              : (this.infoList.age = JSON.parse(res.data.age));
          }
          if (res.data.crowd != null) {
            res.data.crowd == "[]"
              ? (this.infoList.more_people = ["不限"])
              : (this.infoList.more_people = JSON.parse(res.data.crowd));
          }
          if (res.data.net != null) {
            res.data.net == "[]" || res.data.net == null
              ? (this.infoList.network = ["不限"])
              : (this.infoList.network = JSON.parse(res.data.net));
          }
          if (res.data.phone != null) {
            res.data.phone == "[]"
              ? this.infoList.platform.push("不限")
              : (this.infoList.platform = JSON.parse(res.data.phone));
          }
          if (res.data.region != null) {
            if (res.data.region == "[]") {
              this.infoList.city = ["不限"];
            } else {
              let city = JSON.parse(res.data.region);
              let arr = [];
              this.areaArr.forEach((item) => {
                item.children.forEach((subItem) => {
                  if (city.includes(subItem.city_id)) {
                    arr.push([undefined, subItem.city_id]);
                    this.rightCityName.push(subItem.city_name);
                  }
                  this.infoList.city = arr;
                });
              });
            }
          }

          if (res.data.crowd != null) {
            if (res.data.crowd == "[]") {
              this.infoList.more_people = ["不限"];
            } else {
              this.more_status = "more";
              this.infoList.more_people = JSON.parse(res.data.crowd);
              this.rightMoreName = this.infoList.more_people.reduce(
                (sum, cur) => {
                  let obj = this.moreCheckList.find((ele) => ele.crowd == cur);
                  return sum.concat(obj.selectedName);
                },
                []
              );
            }
          }

          if (res.data.industry != null) {
            if (res.data.industry == "[]") {
              this.actionStatus = "不限";
              this.infoList.action = ["不限"];
            } else {
              this.actionStatus = "自定义";
              this.intersetStatus = "自定义";
              let interset = JSON.parse(res.data.industry);
              let arr = [];
              this.intersetOptions.forEach((item) => {
                item.children.forEach((subItem) => {
                  if (interset.includes(subItem.category_id)) {
                    arr.push([item.category_id, subItem.category_id]);
                    this.rightIntersetName.push(subItem.category_name);
                  }
                  this.infoList.action = arr;
                });
              });
            }
          }

          //固定时段,表格数据回显
          if (res.data.put_in_time != "") {
            this.editTable = JSON.parse(res.data.put_in_time);
            this.tableDate = res.data.put_in_time_yes;
            this.tableInit();
            this.formDataWeek(JSON.parse(res.data.put_in_time));
          }
          this.getDirectionData();
        }
      });
    },
    // 固定时段表格数据回显
    formDataWeek(obj) {
      this.weekDate.forEach((item, index) => {
        item.data.forEach((subItem, subIndex) => {
          if (obj.find((ele) => ele == subIndex)) {
            subItem.check = true;
          }
        });
      });
    },
    // 取消创建
    cancel() {
      this.$router.push({
        path: "/liveExpand/adLaunch",
      });
    },
    // 获取定向数据
    getDirectionData() {
      if (
        this.infoList.day_budget &&
        this.infoList.price &&
        this.infoList.delivery_speed &&
        this.infoList.fixed_period
      ) {
        let params = {
          authorization: localStorage.getItem("studentToken") || "",
          budget: this.infoList.day_budget,
          price: this.infoList.price,
          put_in_type: JSON.stringify(this.infoList.delivery_speed),
        };
        if (this.$route.query.id) {
          if (this.infoList.fixed_period[1] == undefined) {
            params.put_in_time = JSON.stringify(this.editTable);
          } else {
            params.put_in_time = JSON.stringify(this.infoList.fixed_period[1]);
          }
          params.advertising_student_config_id = this.$route.query.id;
        } else {
          params.put_in_time = JSON.stringify(this.infoList.fixed_period[1]);
        }
        if (this.infoList.gender) {
          params.sex = this.infoList.gender;
        }

        if (this.infoList.city) {
          this.infoList.city.length != 0
            ? this.infoList.city == "不限"
              ? (params.region = "[]")
              : (params.region = JSON.stringify(
                  this.infoList.city.map((item) => item[1])
                ))
            : "";
        }
        if (this.infoList.age) {
          this.infoList.age.length != 0
            ? this.infoList.age == "不限"
              ? (params.age = "[]")
              : (params.age = JSON.stringify(this.infoList.age))
            : "";
        }
        if (this.infoList.action) {
          this.infoList.action.length != 0
            ? this.infoList.action == "不限"
              ? (params.industry = "[]")
              : (params.industry = JSON.stringify(
                  this.infoList.action.map((item) => item[1])
                ))
            : "";
        }
        if (this.infoList.network) {
          this.infoList.network.length != 0
            ? this.infoList.network == "不限"
              ? (params.net = "[]")
              : (params.net = JSON.stringify(this.infoList.network))
            : "";
        }
        if (this.infoList.platform) {
          this.infoList.platform.length != 0
            ? this.infoList.platform == "不限"
              ? (params.phone = "[]")
              : (params.phone = JSON.stringify(this.infoList.platform))
            : "";
        }
        if (this.infoList.more_people) {
          this.infoList.more_people.length != 0
            ? this.infoList.more_people == "不限"
              ? (params.crowd = "[]")
              : (params.crowd = JSON.stringify(this.infoList.more_people))
            : "";
        }
        getAdInfoData(params).then((res) => {
          if (res.code === 200) {
            this.advertisement = res.data.adv_show_num;
            this.coverage = res.data.user_cover_num;
            this.code = res.data.unique_code;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      } else {
        this.$message({
          type: "error",
          message:
            "(投放速度、投放时间、预算、出价为必填项)不能为空，请检查是否全部填写！",
        });
      }
    },
  },
};
</script>
<style lang="scss">
.msgBoxClass {
  background-color: #0c0e3f !important;
  border: 1px solid #0c0e3f !important;
  p,
  .el-message-box__title {
    color: #ffffff;
  }
  .el-icon-close:before {
    color: #ffffff;
  }
  .el-message-box__content {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .el-message-box__btns {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 80px;
      height: 36px;
    }
  }
  .el-message-box__btns button:nth-child(1) {
    border: 1px solid #262963 !important;
    border-color: #262963 !important;
    color: #d3d3d3;
    background-color: transparent !important;
  }
}
</style>
<style lang="scss" scoped>
@import "./ad.scss";
</style>
